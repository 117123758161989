import React, { useState } from "react";
import { Col, Container, Form, Image, Row, Table } from "react-bootstrap";
import styled from "styled-components";
import BaseLayout from "../components/base-layout";
import MapLocation from "../images/contact-us/map.png";
import Building from "../images/contact-us/real_building.png";
import Woman from "../images/contact-us/woman.png";
import Calling from "../images/icons/calling.png";
import MapIcon from "../images/icons/location.png";
import QuestionMarkIcon from "../images/icons/question.svg";
import Clock from "../images/icons/time.png";
import { FooterIcon } from "../styles/footer-style";
import { BlueColor } from "../styles/global-style";

const TbRow = styled.tr`
  padding: 0.5em 0;
  border-bottom: 2px solid ${BlueColor};
`;

const TbCol = styled.td`
  padding: 0.5rem !important;
`;

const QuestionMark = styled(Image)`
  max-width: 4rem;
`;

const SubTitle = styled.h4`
  font-family: "montserrat-bold";
`;

const ContactUs = () => {
  const [visitorType, setVisitorType] = useState("owner");
  const [nameVal, setNameVal] = useState("");
  const [emailVal, setEmailVal] = useState("");
  const [telephone, setTelephone] = useState("");
  const [subjectVal, setSubjectVal] = useState("");
  const [message, setMessage] = useState("");

  return (
    <BaseLayout title="Contact Us" fullWidth={true} navColor="white">
      <Container fluid>
        <p>
          Jangan ragu menghubungi Kami untuk mendapatkan informasi lebih lanjut:
        </p>

        <Row className="pb-5">
          <Col md="8">
            <Image src={Building} alt="WKI building" fluid />
          </Col>

          <Col md="4">
            <p>
              <strong>Kantor Utama Pemasaran Indonesia :</strong>
            </p>
            <Table responsive borderless size="sm">
              <tbody>
                <TbRow>
                  <TbCol>
                    <FooterIcon src={MapIcon} alt="Map marker Icon" />
                  </TbCol>
                  <TbCol>
                    Pergudangan Gedangan Permai cvBlok B 17 - 18 Jalan Gedangan,
                    No. 214-A, Gedangan - Sidoarjo Jawa Timur, 61254, Indonesia
                  </TbCol>
                </TbRow>

                <TbRow>
                  <TbCol>
                    <FooterIcon src={Clock} alt="Clock Icon" />
                  </TbCol>
                  <TbCol>Senin – Jumat : 08.00 - 17.00 WIB </TbCol>
                </TbRow>

                {/* <TbRow>
                  <TbCol>
                    <FooterIcon src={Calling} alt="Call Icon" />
                  </TbCol>
                  <TbCol>(031) 8191957 / 8012126</TbCol>
                </TbRow> */}

                <TbRow>
                  <TbCol>
                    <FooterIcon src={Calling} alt="Call Icon" />
                  </TbCol>
                  <TbCol>
                    <a href="https://wa.me/6281334954825">+62 813-3495-4825</a>
                  </TbCol>
                </TbRow>
              </tbody>
            </Table>

            <Image src={MapLocation} alt="WKI Factory location" fluid />
          </Col>
        </Row>

        <Row className="pt-5">
          <Col md="6">
            <div className="d-flex justify-content-around align-items-start">
              <QuestionMark src={QuestionMarkIcon} alt="Question Icon" fluid />

              <div className="ml-3">
                <SubTitle className="m-0">
                  <strong>Ask Your Question?</strong>
                </SubTitle>
                <p className="m-0">
                  Setiap pertanyaan yang masuk akan selalu kami balas dengan
                  detail dan jelas.
                </p>
              </div>
            </div>

            <br />
            <br />

            <Form className="text-right">
              <Form.Group controlId="jenis_visitor">
                <Form.Control
                  as="select"
                  name="jenis_visitor"
                  defaultValue={visitorType}
                  onChange={(ev) => setVisitorType(ev.target.value)}
                >
                  <option value="owner">Owner</option>
                  <option value="pegawai">Pegawai</option>
                </Form.Control>
              </Form.Group>

              <Form.Group>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Nama"
                      name="nama"
                      id="nama"
                      onChange={(ev) => setNameVal(ev.target.value)}
                    />
                  </Col>

                  <Col>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      name="email"
                      id="email"
                      onChange={(ev) => setEmailVal(ev.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Telephone"
                      name="telp"
                      id="telp"
                      onChange={(ev) => setTelephone(ev.target.value)}
                    />
                  </Col>

                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Subject"
                      name="subject"
                      id="subject"
                      onChange={(ev) => setSubjectVal(ev.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group controlId="message">
                <Form.Control
                  as="textarea"
                  placeholder="Your Message"
                  rows={3}
                  onChange={(ev) => setMessage(ev.target.value)}
                />
              </Form.Group>

              <a
                className="btn btn-primary"
                href={`mailto:maklon.ptwki@gmail.com?body=\r\r${visitorType} Perusahaan\rNama : ${nameVal}\rEmail : ${emailVal}\rTelp : ${telephone}\r\r${message}&subject=${subjectVal}`}
              >
                Send
              </a>
              {/* <Button
                variant="primary"
                type="button"
                onClick={() => onSendClick()}
              >
                Send
              </Button> */}
            </Form>
          </Col>

          <Col md="6 d-flex justify-content-center align-items-center">
            <Image src={Woman} alt="WKI Customer Services Illustration" fluid style={{maxWidth : '300px'}} />
          </Col>
        </Row>
      </Container>
    </BaseLayout>
  );
};

export default ContactUs;
